<template>
    <div>
        <h1 v-if="success">
            <h2>Successfully logged in! Redirecting you...</h2>
        </h1>
        <div v-else-if="error">
            <h1>Login Error</h1>
            <p>An error occurred and we will now take you back to the homepage.</p>
            <p>
                Please try logging in again and if the problem persists, please tell
                this to an administrator
            </p>
        </div>
        <h1 v-else>{{ title }}</h1>
    </div>
</template>
  
<script>
import { ref } from "vue";
import { useUserStore } from "@/store/user.store";

export default {
    setup() {
        const store = useUserStore();
        let success = false;
        let error = false;
        const qp = Object.fromEntries(new URLSearchParams(window.location.search));

        if (qp.access_token && qp.refresh_token) {
            localStorage.setItem("access_token", qp.access_token);
            localStorage.setItem("refresh_token", qp.refresh_token);
            localStorage.setItem("expires_in", qp.expires_in);
            success = true;
        } else {
            this.$q.notify({
                color: 'negative',
                icon: 'error',
                message: 'Missing Auth JWTs from return url. Please try to log in again. If this error persists, please contact the system administrators.',
                timeout: 2000
            })

            console.error("Got invalid parameters: ", qp);

            // Show error message
            error = true;
        }

        return {
            access_token: qp.access_token,
            refresh_token: qp.refresh_token,
            success: ref(success),
            error: ref(error),
            store
        };
    },
    async mounted() {
        if (this.success) {
            this.store.login(this.$axios);

            this.$q.notify({
                color: 'positive',
                icon: 'check',
                message: 'Successfully logged in!',
                timeout: 2000
            });

            // redirect to my studies page
            setTimeout(() => {
                this.$router.push('/mystudy');
            }, 2000);
        } else {
            this.$q.notify({
                color: 'negative',
                icon: 'error',
                message: 'Error fetching user data. Please try to log in again. If this error persists, please contact the system administrators.',
                timeout: 2000
            });
        }
    }
};
</script>
  
<style scoped>
div {
    width: 100%;
    text-align: center;
}
</style>